<template>
  <div>
    <template v-if="userId && dataList.length">
      <MyBanner :position="1" style="margin-top: 20px;"></MyBanner>
      <div class="container">
        <div class="btn-container border-bottom">
          <h3>书架</h3>
          <div>
            <a-button style="border:2px solid #ff4c39;color:#ff4c39;margin-right:16px"
                      @click="bookRecycleVisible=true;$refs.tbRef.init()">回收站
            </a-button>
            <a-button v-if="isZjerTeacher" style="border:2px solid #ff4c39;color:#ff4c39;margin-right:16px"
                      @click="bookShelfVisible=true">教材书城
            </a-button>
            <a-button type="primary" @click="handleActiveBook">激活教材</a-button>
          </div>
        </div>
        <BookList :dataList="dataList" :isActived="true" @refresh="getDataList"/>
        <a-pagination v-if="dataList.length" class="q-pagination" show-size-changer show-quick-jumper
                      :show-total="total => `共 ${total} 条`" :current="formData.page" :pageSize="formData.size"
                      :total="formData.total" @change="onShowSizeChange"
                      @showSizeChange="onShowSizeChange"></a-pagination>
      </div>
    </template>
    <div class="noLogin" v-else>
      <div class="noLogin-wrapper">
        <MyBanner style="visibility: visible;" class="noLogin-wrapper-MyBanner"></MyBanner>
        <div class="box">
          <img src="@/assets/downloadSection/img_video.svg" alt=""/>
          <a-input-search v-model="activeCode" placeholder="请输入激活码" enter-button="激活" size="large"
                          @focus="!userId&&toLogin()" @input="codeChange()" @search="onSearch"/>
          <div class="error" v-if="!userId">如已激活教材，请<span @click="toLogin">登录</span>使用</div>
          <div class="error" v-if="userId && !dataList.length && !msg">一个激活码仅限一个账号使用

            <span v-if="isZjerTeacher" @click="bookShelfVisible=true" style="float: right;">教材书城 ></span></div>
          <div class="error msg" v-else>{{ msg }} <span v-if="isZjerTeacher" @click="bookShelfVisible=true"
                                                        style="float: right;">教材书城 ></span></div>
        </div>

        <div class="tip">
          <div class="tip-text">
            激活码位置
          </div>
          <img src="@/assets/img_pc.png" alt="" class="tip-img">
        </div>
      </div>
    </div>
    <Footer></Footer>
    <ActiveBook :visible.sync="activeBookVisible"/>

    <a-modal
        title="教材书城"
        :visible="bookShelfVisible"
        @cancel="bookShelfVisible=false"
        :footer="null"
        centered
        :width="840"
    >
      <textbook  ref="tbRef" @add="getDataList()"></textbook>
    </a-modal>
    <a-modal
        title="回收站"
        :visible="bookRecycleVisible"
        @cancel="bookRecycleVisible=false"
        :footer="null"
        centered
        :width="840"
    >
      <textbook :type="2" ref="tbRef" @del="getDataList()"></textbook>
    </a-modal>
  </div>
</template>

<script>
import BookList from '@/components/BookList.vue';
import Footer from '@/components/Footer';
import {activeBook, getUserBookList} from '@/api/videoResource';
import ActiveBook from '@/components/activeBook.vue';
import {buryingPoint} from "@/api/prize";
import MyBanner from '@/components/MyBanner.vue'
import textbook from './textbook.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'VideoResource',
  components: {
    BookList,
    Footer,
    ActiveBook,
    MyBanner,
    textbook
  },
  data() {
    return {
      userId: '',
      dataList: [],
      formData: {type: 1, page: 1, size: 10, total: 0, showFlag: true},
      bookId: '',
      bookShelfVisible: false,
      bookRecycleVisible: false,
      activeBookVisible: false,
      msg: '',
      activeCode: '',
      tbRef:null
    };
  },
  computed: {
    ...mapGetters({isZjerTeacher: "getIsZjerTeacher"}),
  },
  created() {
    if (localStorage.getItem('activeCode')) {
      this.activeCode = localStorage.getItem('activeCode');
    }
    localStorage.removeItem('activeCode');
    const userInfo = localStorage.getItem('user');
    if (userInfo) {
      this.userId = JSON.parse(userInfo).userId;
    }
    this.userId && this.getDataList();
  },
  methods: {
    async getDataList() {
      this.dataList = [];
      const {data} = await getUserBookList(this.userId, {...this.formData});
      this.dataList = data.userBookShelfDTOList;
      this.formData.total = data.totalCount;
    },

    onShowSizeChange(page, size) {
      this.formData.page = this.formData.size != size ? 1 : page;
      this.formData.size = size;
      this.getDataList();
    },

    // 将字符串的字符全部转换为大写字符
    upperCase(str) {
      let arr = str.split('');
      let newStr = '';
      // 通过数组的forEach方法来遍历数组
      arr.forEach(function (value) {
        if (value >= 'a' && value <= 'z') newStr += value.toUpperCase();
        else newStr += value;
      });
      return newStr;
    },

    codeChange() {
      this.activeCode = this.activeCode.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5\-\—]/g, '');
    },

    async onSearch(value) {
      let code = this.upperCase(value.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, ''));

      if (!this.userId) {
        this.toLogin()
        // // 未登录，提示用户登录
        // let that = this;
        // this.$confirm({
        //   content: h => <div style="color: #666;">请登录后再进行激活</div>,
        //   onOk() {
        //     that.toLogin();
        //   },
        //   onCancel() {
        //     // console.log('Cancel')
        //   },
        //   okText: '立即登录',
        //   okType: 'danger',
        //   centered: true,
        //   cancelText: '取消',
        //   class: 'test'
        // });
        return;
      }

      if (!value) {
        this.msg = '请输入激活码';
        return;
      } else {
        const res = await activeBook({
          userId: this.userId,
          bookId: this.bookId,
          activationCode: code
        }).catch(err => {
          this.msg = err.data.msg;
        });
        if (res) {
          // 激活埋点
          await buryingPoint({
            activityCode: localStorage.getItem('activityCode') || 'E_STUDY',
            actionCode: 'ACTIVATION',
            userId: JSON.parse(localStorage.user)?.userId,
            otherParams: 'activationCode#' + code
          });
          this.getDataList();
        }
      }
    },

    handleActiveBook() {
      this.activeBookVisible = true;
    },

    toLogin() {
      localStorage.setItem('activeCode', this.activeCode);
      this.$router.push('/loginRegistration?loginStep=1&redirect=/videoResource');
    }
  }
};
</script>

<style scoped lang="less">
/deep/ .ant-pagination-options {
  position: relative;
}

.container {
  width: 1200px;
  margin: 16px auto;
  background: #fff;
  padding: 0 16px;

  .q-pagination {
    text-align: center;
    margin-top: 32px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    font-family: PingFangSC-Regular;
    font-weight: 600;

    .ant-btn {
      font-size: 16px;
    }
  }
}

/deep/ .ant-btn-primary {
  background-color: #ff4c39 !important;
  border: none;
}

.noLogin {
  padding-top: 20px;
  height: 780px;
  background: url('~@/assets/downloadSection/active_bg.png') center no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  text-align: center;

  &-wrapper {
    text-align: center;

    &-MyBanner {
      margin: 0 0 20px;
    }
  }

  .box {
    width: 480px;
    margin: auto;

    > img {
      width: 188px;
      height: 188px;
      margin-bottom: 52px;
    }
  }


  /deep/ .ant-input-group .ant-input {
    width: 356px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    padding-left: 24px;
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #cccccc;
    line-height: 18px;
    height: 48px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .error {
    margin-top: 16px;
    text-align: left;
    padding: 0 24px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #999999;
    line-height: 14px;

    span {
      color: #ff4c39;
      cursor: pointer;
    }
  }

  .tip {
    text-align: left;
    width: 480px;
    margin: auto;
    padding: 0 24px;

    &-text {
      margin: 40px 0 10px;
      font-size: 16px;
      color: #999999;
    }

    &-img {
      width: 100%;
      object-fit: contain;
    }
  }

  .msg {
    color: #ff4c39;
  }

  /deep/ .ant-input-group .ant-input:focus {
    border: none;
  }

  /deep/ .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
  /deep/ .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
    width: 120px;
    height: 40px;
    border-radius: 20px;
    background-color: #ff573f;
    border: none;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #ffffff;
    line-height: 16px;
  }

  /deep/ .ant-input-search-enter-button + .ant-input-group-addon,
  /deep/ .ant-input-search-enter-button input + .ant-input-group-addon {
    background-color: #fff;
    width: 124px;
    padding-right: 4px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
</style>
