import { render, staticRenderFns } from "./videoResource.vue?vue&type=template&id=0a538a85&scoped=true&"
import script from "./videoResource.vue?vue&type=script&lang=js&"
export * from "./videoResource.vue?vue&type=script&lang=js&"
import style0 from "./videoResource.vue?vue&type=style&index=0&id=0a538a85&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a538a85",
  null
  
)

export default component.exports